import './styles.scss';
import React, {memo, useEffect, useState} from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import parse from 'html-react-parser';
// import {
//     academyArrow
// } from "../../images";

type Props = {
    number: string,
    image: string,
    title: string,
    subtitle: string
}

const CorporativeBookItem = ({number, image, title, subtitle}: Props) => {

    // const [imageStatus, setImageStatus] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    // const handleImageStatus = () => {
    //     setTimeout(() => {
    //         setImageStatus(true);
    //         console.log('Fires');
    //     }, 700);
    // }

    useEffect(() => {
        const img = new Image();
        img.src = image;

        const handleImageLoad = () => {
            setTimeout(() => {
                setIsImageLoaded(true);
            }, 500);
        };

        img.addEventListener('load', handleImageLoad);

        return () => {
            img.removeEventListener('load', handleImageLoad);
        };
    }, [image]);

    // useEffect(() => {
    //     if (isImageLoaded) {
    //         setImageStatus(true);
    //     }
    // }, [isImageLoaded]);

    return (
        <a href="https://apps.apple.com/us/app/feelgrow-business-education/id1630377140" className="corporativeBooksItem" key={number}>
            <div className={`corporativeBooksImage ${isImageLoaded && 'active'}`}>
                <img src={image} alt="" />
                {
                    !isImageLoaded && <Skeleton className="corporativeBooksSkeleton" />
                }
            </div>
            <div className="corporativeBooksInfo">
                <span>{title}</span>
                <p>{subtitle}</p>
            </div>
        </a>
    )
}

export default memo(CorporativeBookItem);
